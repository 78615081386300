@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1a2c48;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  font-family: "Poppins", sans-serif;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy"),
    url("./fonts/Gilroy.woff") format("truetype");
  font-weight: bold;
}


@font-face {
  font-family: "Peculiar_People";
  src: local("Peculiar_People.ttf"),
    url("./fonts/Peculiar_People.ttf") format("truetype");
  font-weight: light;
}


@font-face {
  font-family: "Ginchiest";
  src: local("Ginchiest"),
    url("./fonts/Ginchiest.woff") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "America";
  src: local("America"),
    url("./fonts/American-Captain.woff") format("truetype");
}

@font-face {
  font-family: "Bahnschrift";
  src: local("Bahnschrift"),
    url("./fonts/BAHNSCHRIFT.woff") format("truetype");
}


.bg-image-blur {
  background-size: cover;
  background-color: rgb(255, 255, 255);
  background-position: center center;
  opacity: 0.3;
  filter: blur(8px);
  -webkit-mask: linear-gradient(rgb(255, 255, 255), transparent);
}

.left-50 {
  left: calc(50% - 55px);
}

.top-50 {
  top: calc(50% - 55px);
}

.wheel > div {
  max-width: 450px !important;
  max-height: 450px !important;
}


.ginchiest {
  font-family: 'Ginchiest';
}

.america {
  font-family: 'America';
}

.bahnschrift {
  font-family: 'Bahnschrift';
}

.MuiTypography-root {
  color: white;
}

.MuiButton-label {
  color: white;
}

.MuiButton-containedPrimary{
  background-color: white !important;
}

.MuiButton-label {
  color: black !important;
  font-family: 'Bahnschrift' !important;
  text-transform: none !important;
}

.MuiButton-iconSizeMedium  {
  display: none !important;
}

.MuiList-root {
  background-color: white !important;
}
.MuiListItem-root{
  box-shadow: inset 0 1px 0 0 rgb(0 0 0 / 10%) !important;
}



.title {
  color: white;
  background-color: #f3ec78;
  background-image: linear-gradient(45deg, #dc1fff 15%, #00ffa3 65%);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  text-transform: uppercase;
}

.title-color {
  color: #00ffa3;
  background-color: #f3ec78;
  background-image: linear-gradient(45deg, #dc1fff 35%, #00ffa3 65%);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}


.mask {
  mask-image: linear-gradient(to bottom, rgba(26,44,72,1), rgba(26,44,72,0));
}

a {
  color: white !important;
}

.spin-title {
  letter-spacing: 2px;
  -webkit-text-stroke: 0.5px #ccc;
  text-shadow: 0 1px 0 #ccc,
               0 2px 0 #c9c9c9,
               0 3px 0 #bbb,
               0 4px 0 #b9b9b9,
               0 5px 0 #aaa,
               0 6px 1px rgba(0,0,0,.1),
               0 0 5px rgba(0,0,0,.1),
               0 1px 3px rgba(0,0,0,.3),
               0 3px 5px rgba(0,0,0,.2),
               0 5px 10px rgba(0,0,0,.25),
               0 10px 10px rgba(0,0,0,.2),
               0 20px 20px rgba(0,0,0,.15);
}

.bg-wheel {
  background: url("/public/solyetis/wheel-border.png");
  background-size: contain;
  border-radius: 50%;
  background-repeat: no-repeat;
  position: relative;
  background-size: 510px 510px;
  background-position: center;
}

.outer-bg {
  background: url("/public/solyetis/wheel-border.png"), #2c1111;
  background-size: contain;
  background-repeat: no-repeat;
  border: 8px solid #807367;
  border-radius: 50%;
  position: relative;
  background-size: 570px 580px;
  background-position: center;
}

@media only screen and (max-width: 800px) {
  .outer-bg {
    background: unset;
    background-size: unset;;
    border-radius: unset;
    border: unset;
    background-repeat: unset;
    position: unset;
    background-position: unset;
  }

  .bg-wheel {
    background: unset;
    background-size: unset;;
    border-radius: unset;
    border: unset;
    background-repeat: unset;
    position: unset;
    background-position: unset;
  }


  .left-50 {
    left: calc(50% - 50px);
  }
  
  .top-50 {
    top: calc(50% - 55px);
  }
  
}